import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../redux/slices/cartSlice';
// import infoImg from '../../assets/img/info.svg';

const ItemBlock = ({
  id,
  title,
  price,
  imageUrl,
  sizes,
  weight,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const [activeSize, setActiveSize] = React.useState(sizes[0]);
  const [activeWeight, setActiveWeight] = React.useState(weight[0]);
  const [activeSizeValue, setActiveSizeValue] = React.useState(sizes[0]?.value);
  const [activeWeightValue, setActiveWeightValue] = React.useState(weight[0]?.value);
  const [pricer, setPricer] = React.useState(activeSize?.price || activeWeight?.price);

  const popupRet = React.useRef();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.composedPath().includes(popupRet.current)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => document.body.removeEventListener('click', handleClickOutside);
  }, []);

  const handleSizeClick = (size) => {
    setActiveSize(size);
    setActiveSizeValue(size.value);
    setPricer(size.price);
  };

  const handleWeightClick = (weight) => {
    setActiveWeight(weight);
    setActiveWeightValue(weight.value);
    setPricer(weight.price);
  };

  React.useEffect(() => {
    const selectedSize = sizes.find((size) => size.value === activeSizeValue);
    const selectedWeight = weight.find((weight) => weight.value === activeWeightValue);

    if (selectedSize && selectedSize.price) {
      setPricer(selectedSize.price);
    } else if (selectedWeight && selectedWeight.price) {
      setPricer(selectedWeight.price);
    }
  }, [activeSizeValue, activeWeightValue, sizes, weight]);

  const cartItem = cartItems.find(
    (item) => item.id === id && item.size === activeSizeValue && item.weight === activeWeightValue,
  );
  const addedCount = cartItem ? cartItem.count : 0;

  const onClickAdd = () => {
    const item = {
      id,
      title,
      pricer,
      imageUrl,
      size: activeSizeValue,
      weight: activeWeightValue,
    };

    dispatch(addItem(item));
  };

  return (
    <div className="item-block">
      <div className="item-block__img">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.29,3.71a1,1,0,0,0,1.42,0,1.15,1.15,0,0,0,.21-.33A1,1,0,0,0,21,3a1,1,0,0,0-.29-.71l-.15-.12a.76.76,0,0,0-.18-.09,1,1,0,0,0-1.09.21A1,1,0,0,0,19,3a1,1,0,0,0,.08.38A1.15,1.15,0,0,0,19.29,3.71ZM20,5a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V6A1,1,0,0,0,20,5Zm.06,8a1,1,0,0,0-1.11.87A7,7,0,0,1,12,20H6.41l.64-.63a1,1,0,0,0,0-1.41A7,7,0,0,1,12,6a6.91,6.91,0,0,1,3.49.94,1,1,0,0,0,1-1.72A8.84,8.84,0,0,0,12,4,9,9,0,0,0,5,18.62L3.29,20.29a1,1,0,0,0-.21,1.09A1,1,0,0,0,4,22h8a9,9,0,0,0,8.93-7.88A1,1,0,0,0,20.06,13Z"
            fill="#FFFFFF"
          />
        </svg>
      </div>
      <img
        ref={popupRet}
        onClick={handleImageClick}
        className="item-block__image"
        src={imageUrl}
        alt=""
      />
      {isOpen && (
        <div className="item-block__popupq">
          <div className="item-block__popupq-content">
            <h2 className="item-block__popupq-title">{title}</h2>
            <ul className="item-block__popupq-wrapper">
              <li className="item-block__popupq-text">{description1}</li>
              <li className="item-block__popupq-text">{description2}</li>
              <li className="item-block__popupq-text">{description3}</li>
              <li className="item-block__popupq-text">{description4}</li>
              <li className="item-block__popupq-text">{description5}</li>
              <li className="item-block__popupq-text">{description6}</li>
            </ul>

            {/* <button className="item-block__popupq-close" onClick={closePopup}>
              <svg
                enableBackground="new 0 0 32 32"
                height="32px"
                id="Capa_1"
                version="1.1"
                viewBox="0 0 32 32"
                width="32px">
                <path d="M16,4C9.383,4,4,9.383,4,16s5.383,12,12,12s12-5.383,12-12S22.617,4,16,4 M16,2c7.732,0,14,6.268,14,14s-6.268,14-14,14  S2,23.732,2,16S8.268,2,16,2L16,2z M19.536,11.05l1.414,1.414L17.414,16l3.536,3.536l-1.414,1.414L16,17.414l-3.536,3.536  l-1.414-1.414L14.586,16l-3.536-3.536l1.414-1.414L16,14.586L19.536,11.05z" />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </button> */}

            {/* <button className="button item-block__popupq-close" onClick={closePopup}>
              Закрыть
            </button> */}
          </div>
        </div>
      )}

      <h4 className="item-block__title">{title}</h4>
      <div className="item-block__selector">
        <ul>
          {sizes.map((size) => (
            <li
              key={size.value}
              onClick={() => handleSizeClick(size)}
              className={activeSizeValue === size.value ? 'active' : ''}>
              {size.value} мл.
            </li>
          ))}
        </ul>
        <ul>
          {weight.map((weight) => (
            <li
              key={weight.value}
              onClick={() => handleWeightClick(weight)}
              className={activeWeightValue === weight.value ? 'active' : ''}>
              {weight.value} гр.
            </li>
          ))}
        </ul>
      </div>
      <div className="item-block__bottom">
        <div className="item-block__price"> {pricer} ₽ </div>
        <button onClick={onClickAdd} className="button button--outline button--add">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"></path>
          </svg>
          <span>Добавить</span>
          {addedCount > 0 && <i>{addedCount}</i>}
        </button>
      </div>
    </div>
  );
};

export default ItemBlock;
