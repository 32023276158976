import React from 'react';

const Cookie = () => {
  return (
    <div className="container">
      <div className="cookie">
        <div className="cookie__title">Политика конфендециальности персогальных данных</div>
        <div className="cookie__text">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quidem suscipit corrupti
            quo nihil mollitia neque illum quaerat debitis nostrum?Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Qui repudiandae rerum nam amet iste, sequi, earum animi ex
            nihil facilis sunt delectus similique? Aut assumenda, autem earum vitae perferendis
            aliquid totam quas neque consectetur quis a consequuntur ipsum obcaecati laborum dolorum
            velit fuga odio labore, voluptatum quisquam aspernatur alias adipisci.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
