import React from 'react';
// import testImg from '../assets/img/cartItem/oil-05.jpeg';
// import testImg2 from '../assets/img/cartItem/oil-06.jpeg';
import omegaImg from '../assets/img/omega.png';
import natureImg from '../assets/img/nature.png';
import bottleImg from '../assets/img/bottle.png';
import noSugarImg from '../assets/img/no_sugar.png';
import jarImg from '../assets/img/jar.png';
import flourImg from '../assets/img/flour.png';
import goodImg from '../assets/img/good.png';
import cleanImg from '../assets/img/cleaning.png';
import cakeImg from '../assets/img/cake.png';

const AboutUs = () => {
  const pastsRef = React.useRef(null);
  const oilRef = React.useRef(null);
  const flourRef = React.useRef(null);
  const oilCakeRef = React.useRef(null);

  React.useEffect(() => {
    if (window.location.hash === '#pasts') {
      pastsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#oil') {
      oilRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#flour') {
      flourRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#oilCake') {
      oilCakeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="aboutUs">
          <span className="aboutUs__sub">
            Дорогие друзья, приветствуем в нашем магазине «Jiva» Читается, как «джива», а само слово
            означает «жизнь/живой». <span className="yep">А «живые» наши продукты потому что:</span>
          </span>
          {/* <h2 className="aboutUs__subtitle"></h2> */}
          <div className="aboutUs__text">
            <div ref={oilRef} id="oil">
              <img src={natureImg} alt="" />
              <p>
                <strong>Для производства масел </strong> выбираем живые семена, которые способны
                прорасти. Семечка привычного белого кунжута не прорастет - пробовали. Белый кунжут -
                шлифованный, потому используем коричневый кунжут.
              </p>
            </div>
            <div>
              <img src={omegaImg} alt="" />
              <p>
                <strong>Отжим масла</strong> - серьезный процесс, в котором задача сохранить «омеги»
                (омега-3-6-9), которые повышают полезность масла и разрушаются при нагреве. Для
                этого используется метод холодного прессования семян и орехов без нагрева.
              </p>
            </div>
            <div>
              <span className="aboutUs-raw">RAW</span>
              <p>
                <strong>Не подвергаем обжарке</strong> или нагреву семена или орехи - оставляем
                "сырыми" (живыми). Масла из таких семян называют "сыродавленными". Поэтому у масел
                нет яркого выраженного аромата.
              </p>
            </div>
            <div>
              <img src={cleanImg} alt="" />
              <p ref={pastsRef}>
                <strong>Для получения свежих масел</strong> без нежелательных примесей старого масла
                используем капролоновые бочонки - из безвредного пластика, которые не пропитываются
                маслом  и легко очищаются. Деревянные бочонки неминуемо пропитываются насквозь
                маслом, которое накапливается и окисляется в стенках бочонка.
              </p>
            </div>
            <div>
              <img src={bottleImg} alt="" />

              <p>
                <strong>Полезность масел сохраняется</strong> так же за счет избегания процессов
                окисления в результате длительного контакта с воздухом, металлом и солнечным светом
                в процессе производства.
              </p>
            </div>

            <div ref={pastsRef}>
              <img src={goodImg} alt="" />
              <p id="pasts">
                <strong>Наши пасты хороши</strong> - это правда. Точнее хороши сами по себе орехи,
                из которых состоят. Если любите какой то орех - паста из него понравится тоже. На
                разного типа мельницах орехи измельчаются до состояния пасты, сохраняя при этом
                полезность.
              </p>
            </div>

            <div>
              <img src={noSugarImg} alt="" />
              <p>
                <strong>Подсластитель</strong> - сироп топинамбура. Растение выглядит, как маленький
                подсолнух, по вкусу и цвету сироп напоминает молодой цветочный мёд. Сахар - не
                используем.
              </p>
            </div>
            <div>
              <img src={jarImg} alt="" />
              <p>
                <strong>Естественное расслоение паст</strong> - свидетельство натуральности состава.
                Через неделю хранения пасты сверху появляется масло, а измельченный орех остается
                внизу. Так происходит ввиду отсутствия стабилизаторов в составе. Восстановить
                однородность пасты можно перемешав ложкой.
              </p>
            </div>
            <div>
              <img src={cakeImg} alt="" />
              <p ref={oilCakeRef}>
                <strong>Жмых</strong> - часто недооцененный продукт. Для получения жмыха необходимо
                убрать масло из семян или орехов, сохранив вкусовые и питательные качества. И в
                результате приготовления жмыха останется масло.
              </p>
            </div>
            <div>
              <img src={flourImg} alt="" />
              <p ref={flourRef}>
                <strong>Мука</strong> – измельченный жмых орехов или семян с пониженным жиром, как в
                молоке или сливках (от 2,5 до 10%). Кедровое, кокосовое или другое молоко делается
                простым перемешиванием муки с водой. Мука хороша для выпечки или как суперфуд.
              </p>
            </div>
          </div>
          <div className="aboutUs__end">
            <p>
              Сберечь природный потенциал семян и орехов, придать вкусную, усвояемую,
              концентрированную и удобную форму масла, пасты, муки или жмыха - вот наша цель.
              <br /> И у нас это получилось!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
