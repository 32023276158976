import { configureStore } from '@reduxjs/toolkit';
import filter from './slices/filterSlice';
import cart from './slices/cartSlice';
import jiva from './slices/jivaSlice';

export const store = configureStore({
  reducer: {
    filter,
    cart,
    jiva,
  },
});
