import React from 'react';
import instImg from '../assets/img/socials/inst.svg';
import tgImg from '../assets/img/socials/telegram.svg';
import whatsImg from '../assets/img/socials/whatsapp.svg';
import phoneImg from '../assets/img/socials/phone.png';
import mailImg from '../assets/img/socials/mail.png'

const Contacts = () => {
  return (
    <div className="container">
      <div className="contacts">

      <span className="contacts__subtitle">
          <strong>Оплата</strong> осуществляется в рублях курьеру либо безналичным способом.{' '}
        </span>
        <p className="contacts__text">
          <strong>Возврат товара</strong>, сохранившего свой первоначальный вид, может быть совершен в течение 14
          календарных дней. 
        </p>
        <p className="contacts__text">
        <strong>Возврат денежных средств </strong>
          осуществляется по факту получения товара.
        </p>
        <div className="contacts__content">
            <div className='contacts__content-wrapper'>
            <a href="https://www.instagram.com/jivalab.ru" target="_blank" rel="noreferrer">
              <img src={instImg} alt="" /> jivalab.ru
            </a>
            </div>
            <div className='contacts__content-wrapper'>
            <a href="https://t.me/julia_lamanova"target="_blank" rel="noreferrer">
              <img src={tgImg} alt="" /> @julia_lamanova
            </a>

            </div>
            <div className='contacts__content-wrapper'>
            <a href="https://wa.me/79235003569" target="_blank" rel="noreferrer">
              <img src={whatsImg} alt="" /> 8 (923) 500 35 69
            </a>

            </div>
            <div className='contacts__content-wrapper'>
            <a href="mailto:mail@jivalab.ru">
              <img src={mailImg} alt="" /> mail@jivalab.ru
            </a>

            </div>
           <div className='contacts__content-wrapper'>
           <a href="tel:89235003569">
              <img src={phoneImg} alt="" />8 (923) 500 35 69
            </a>
           </div>
        </div>
        <div className='contacts__rek'>
        <span><strong>ИП Ламанова Ю.В.</strong></span>
        <span>Кузбасс, Кемерово, Гагарина 122.</span>
        <span><strong>ИНН 420543086580</strong></span>
        <span>ОГРНИП 322420500020732</span>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
