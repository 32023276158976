import React from 'react';
import deliveryImg from '../assets/img/delivery.png';
import homeImg from '../assets/img/home.png';
import packageImg from '../assets/img/package.png';

const Delivery = () => {
  return (
    <div>
      <div className="container">
        <div className="delivery">
          {/* <h1 className="delivery__title">Доставка</h1> */}
          <div className="delivery__wrapper">
            <div className="delivery__content">
              <img className="delivery__content-img" src={homeImg} alt="почта россии" />

              <div className="delivery__content-wrapper">
              <span className="delivery__content-title">Мы находимся в Кемерово.</span>
              <p className="delivery__content-text">
                <strong>Самостоятельно забрать заказ</strong> вы можете в любой день (по предварительной
                договоренности) по адресу: Кемеровский район, деревня{' '}
                <strong>Сухово, улица Ботаническая, дом 42</strong>
              </p>
              </div>
            </div>
            <div className="delivery__content">
              <img className="delivery__content-img" src={deliveryImg} alt="почта россии" />
              <div className="delivery__content-wrapper">
                <span className="delivery__content-title">Доставка по Кемерово</span>
                <p className="delivery__content-text">
                  Бесплатно доставляем заказы во <strong>вторник и пятницу</strong>, а в любой другой
                  день отправим заказ с помощью курьерского сервиса. Тарифы на доставку таким
                  способом немногим дороже поездке на такси
                </p>
              </div>
            </div>
            <div className="delivery__content">
              <img className="delivery__content-img" src={packageImg} alt="почта россии" />
              <div className="delivery__content-wrapper">
                <span className="delivery__content-title">Доставка в другие города</span>
                <p className="delivery__content-text">
                  Бережно упакуем и оправим транспортной компанией по всей России. Свяжемся с вами
                  для согласования всех деталей, как получим заказ.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
