import React from 'react';
import { Link } from 'react-router-dom';
import inst from '../assets/img/socials/inst.svg';
import tg from '../assets/img/socials/telegram.svg';
import whats from '../assets/img/socials/whatsapp.svg';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__contacts">
            <a className="footer__contacts-tel" href="tel:+79235003569">
              8 (923) 500 35 69
            </a>
            <a className="footer__contacts-email" href="mailto:mail@jivalab.ru">
              mail@jivalab.ru
            </a>
          </div>
          <div className="footer__text">
            <Link className='footer__text-cookie' to='/cookie'>Политика конфиденциальности и обработки персональных данных</Link>
            <span className="footer__copiright">© 2023 JIVA</span>

          </div>
          <div className="footer__socials">
            <a href="https://www.instagram.com/jivalab.ru" target="_blank" rel="noreferrer">
              <img src={inst} alt="" />
            </a>
            <a href="https://t.me/julia_lamanova"target="_blank" rel="noreferrer">
              <img src={tg} alt="" />
            </a>
            <a href="https://wa.me/79235003569" target="_blank" rel="noreferrer">
              <img src={whats} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
