import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const displayed = localStorage.getItem('modalDisplayed');
    if (!displayed) {
      this.setState({ open: true });
      localStorage.setItem('modalDisplayed', 'true');
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      this.state.open && (
        <div className="container">
          <div className="modal">
            <div className="modal__content">
              <p className="modal__content-text">
                Этот сайт использует файлы cookie для улучшения алгоритмов взаимодействия с
                пользователем, анализа работы и улучшения сайта. Продолжая пользоваться сайтом, вы
                даете согласие на обработку файлов cookie и пользовательских данных. Если вы не
                хотите, что бы ваши данные обробатывались, пожалуйста покинте сайт.
              </p>
              <div className="modal__content-wrapper">
                <Link to="/cookie" className="modal__content-info">
                  Больше информации
                </Link>
                <button className="modal__exit" onClick={() => this.handleClose()}>
                  <svg
                    enableBackground="new 0 0 32 32"
                    height="32px"
                    id="Capa_1"
                    version="1.1"
                    viewBox="0 0 32 32"
                    width="32px">
                    <path
                      d="M16,4C9.383,4,4,9.383,4,16s5.383,12,12,12s12-5.383,12-12S22.617,4,16,4 M16,2c7.732,0,14,6.268,14,14s-6.268,14-14,14  S2,23.732,2,16S8.268,2,16,2L16,2z M19.536,11.05l1.414,1.414L17.414,16l3.536,3.536l-1.414,1.414L16,17.414l-3.536,3.536  l-1.414-1.414L14.586,16l-3.536-3.536l1.414-1.414L16,14.586L19.536,11.05z"
                    />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default Modal;
